import { defineStore } from "pinia";

export const usePreferencesStore = defineStore("preferences", () => {
    function getDarkMode() {
        let preference = JSON.parse(localStorage.getItem("darkMode"));

        if (preference === null) {
            preference = window.matchMedia("(prefers-color-scheme: dark)").matches;

            localStorage.setItem("darkMode", preference);
        }

        return preference;
    }

    const state = {
        darkMode: getDarkMode(),
    };

    const actions = {
        toggleDarkMode() {
            this.setDarkMode(!state.darkMode);
        },
        setDarkMode(value) {
            if (value === state.darkMode) return;

            state.darkMode = value;
            localStorage.setItem("darkMode", value);

            // Reload the page to apply the new theme
            // TODO: Find a better way to apply the new theme
            location.reload();
        }

    };

    return { state, actions };
});
